import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import GatsbyImage from 'gatsby-image';

import Container from '../container/container';

import './image-and-text.scss';

const ImageAndText = ({children, img, id, bg}) => {
    return (
        <BackgroundImage Tag="section" id={id} className="image-and-text" fluid={bg} >
            <Container>
                    <div className="text-content">
                        {children}
                    </div>
                    <div className="image">
                        <GatsbyImage fluid={img} />
                    </div>
            </Container>
        </BackgroundImage>
    )
}

export default ImageAndText;